﻿import { postJSON, toggleClass } from "./Helper";

export interface LoginControllerConfig {
    Form: HTMLFormElement,
    ResetPasswordForm: HTMLFormElement,
    UsernameInput: HTMLInputElement,
    PasswordInput: HTMLInputElement,
    TotpInput: HTMLInputElement,
    TotpInputGroup: HTMLInputElement,
    Fido2LoginButton: HTMLButtonElement;
    LoginButton: HTMLButtonElement;
    ErrorMessageLabel: HTMLElement
}

interface LoginRequest {
    username: string;
    credentials: UserCredential[];
}

interface LoginResult {
    loggedIn: boolean;
    loginMessage: string;
    requiredCredentials: UserCredential[];
    redirectUri: string;
}

interface UserCredential {
    type: string;
    value: string;
    challenge: string;
}

interface ResetPasswordStep1Request {
    username: string;
}


export class LoginController {
    private config: LoginControllerConfig;
    private isExternalEnv: boolean;

    constructor(config: LoginControllerConfig, isExternalEnv: boolean) {
        this.config = config;
        this.isExternalEnv = isExternalEnv;
        this.AttachEventHandlers();
    }

    private AttachEventHandlers = () => {
        this.config.Form?.addEventListener('submit', this.OnLoginFormSubmit);
        this.config.ResetPasswordForm?.addEventListener('submit', this.OnResetPasswordFormSubmit);
        this.config.Fido2LoginButton?.addEventListener('click', this.OnFido2LoginButtonClick);
    }

    private OnFido2LoginButtonClick = (e) => {
        e.preventDefault();

        var request = {
            username: this.config.UsernameInput.value
        };

        postJSON("/api/authentication/v1/assertionOptions", request, this.isExternalEnv)
            .then(result => this.onAssertionOptionsSuccess(result))
            .catch(reason => console.log(reason))
    }

    private onAssertionOptionsSuccess(makeAssertionOptions) {
        console.log("Assertion Options Object", makeAssertionOptions);
        makeAssertionOptions = makeAssertionOptions.options;

        // show options error to user
        if (makeAssertionOptions.status !== "ok") {
            console.log("Error creating assertion options");
            console.log(makeAssertionOptions.errorMessage);
            console.error(makeAssertionOptions.errorMessage);
            return;
        }

        // todo: switch this to coercebase64
        const challenge = makeAssertionOptions.challenge.replace(/-/g, "+").replace(/_/g, "/");
        makeAssertionOptions.challenge = Uint8Array.from((<any>atob(challenge)), c => (<any>c).charCodeAt(0));

        // fix escaping. Change this to coerce
        makeAssertionOptions.allowCredentials.forEach(function (listItem) {
            var fixedId = listItem.id.replace(/\_/g, "/").replace(/\-/g, "+");
            listItem.id = Uint8Array.from((<any>atob(fixedId)), c => (<any>c).charCodeAt(0));
        });

        console.log("Assertion options", makeAssertionOptions);

        // ask browser for credentials (browser will ask connected authenticators)
        let credential;
        try {
            credential = navigator.credentials.get({ publicKey: makeAssertionOptions }).then((assertedCredential) => {
                console.log("promise assertedCredentials", assertedCredential);
                try {
                    //await verifyAssertionWithServer(credential);
                    // Move data into Arrays incase it is super long
                    let authData = new Uint8Array((<any>assertedCredential).response.authenticatorData);
                    let clientDataJSON = new Uint8Array((<any>assertedCredential).response.clientDataJSON);
                    let rawId = new Uint8Array((<any>assertedCredential).rawId);
                    let sig = new Uint8Array((<any>assertedCredential).response.signature);
                    const data = {
                        id: assertedCredential.id,
                        rawId: this.coerceToBase64Url(rawId),
                        type: assertedCredential.type,
                        extensions: (<any>assertedCredential).getClientExtensionResults(),
                        response: {
                            authenticatorData: this.coerceToBase64Url(authData),
                            clientDataJson: this.coerceToBase64Url(clientDataJSON),
                            signature: this.coerceToBase64Url(sig)
                        }
                    };

                    try {
                        let credentials = this.GetCredentials();
                        credentials.push({
                            type: "fido2",
                            value: JSON.stringify(data),
                            challenge: ""
                        });

                        this.PerformLoginRequest(credentials);

                        /*var queryParams = window.location.href.slice(window.location.href.indexOf('?') + 1)

                        jQuery.ajax({
                            //"url": baseURL + "api/authentication/v1/makeAssertion",
                            "url": baseURL + "api/authentication/login?" + queryParams,
                            "dataType": "json",
                            "type": "post",
                            "contentType": "application/json",
                            "data": JSON.stringify({ ClientResponse: data, username: "test" }),
                            "success": function (response) {

                                console.log("Assertion Object", response);

                                // show error
                                if (response.status !== "ok") {
                                    console.log("Error doing assertion");
                                    console.log(response.errorMessage);
                                    console.error(response.errorMessage);
                                    return;
                                }
                                console.log("Login successful!");
                            }
                        });*/
                    } catch (e) {
                        console.error("Could not verify assertion", e);
                    }
                } catch (e) {
                    console.error("Could not verify assertion", e);
                }
            });
        } catch (err) {
            console.error(err.message ? err.message : err);
        }
    }

    private coerceToBase64Url(thing) {
        // Array or ArrayBuffer to Uint8Array
        if (Array.isArray(thing)) {
            thing = Uint8Array.from(thing);
        }

        if (thing instanceof ArrayBuffer) {
            thing = new Uint8Array(thing);
        }

        // Uint8Array to base64
        if (thing instanceof Uint8Array) {
            var str = "";
            var len = thing.byteLength;

            for (var i = 0; i < len; i++) {
                str += String.fromCharCode(thing[i]);
            }
            thing = window.btoa(str);
        }

        if (typeof thing !== "string") {
            throw new Error("could not coerce to string");
        }

        // base64 to base64url
        // NOTE: "=" at the end of challenge is optional, strip it off here
        thing = thing.replace(/\+/g, "-").replace(/\//g, "_").replace(/=*$/g, "");

        return thing;
    };

    private OnResetPasswordFormSubmit = (e) => {
        e.preventDefault();

        var username = this.config.UsernameInput.value
        var request: ResetPasswordStep1Request = {
            username: username
        }

        postJSON<void>("/api/authentication/v1/resetPasswordStep1", request, this.isExternalEnv)
            .then(() => this.OnResetPasswordSuccess())
            .catch(reason => this.OnResetPasswordFail())
    }

    private OnResetPasswordSuccess() {
        document.getElementById("changePassword-error-message").classList.add("d-none");
        document.getElementById("changePassword-success-message").classList.remove("d-none");
        setTimeout(function () { document.location.href = "resetpasswordstep2.html" }, 2000);
    }

    private OnResetPasswordFail() {
        document.getElementById("changePassword-success-message").classList.add("d-none");
        document.getElementById("changePassword-error-message").classList.remove("d-none");
    }

    private GetCredentials() {
        let credentials: UserCredential[] = [];

        credentials.push({
            type: "password",
            value: this.config.PasswordInput.value,
            challenge: ""
        });

        let otp: string = this.config.TotpInput.value;
        if (otp != "") {
            credentials.push({
                type: "totp",
                value: otp,
                challenge: ""
            });
            credentials.push({
                type: "hwtotp",
                value: otp,
                challenge: ""
            });
        }

        return credentials;
    }

    private PerformLoginRequest(credentials: UserCredential[] = null) {
        credentials = credentials == null ? this.GetCredentials() : credentials;

        let request: LoginRequest = {
            username: this.config.UsernameInput.value,
            credentials: credentials
        };

        var queryParams = window.location.href.slice(window.location.href.indexOf('?') + 1)

        postJSON("/api/authentication/login?" + queryParams, request, this.isExternalEnv)
            .then(result => this.OnLoginSuccess(result))
            .catch(reason => this.OnLoginFail(reason))
    }

    private OnLoginFormSubmit = (e) => {
        e.preventDefault()

        this.PerformLoginRequest();
    }

    private OnLoginSuccess = (response: LoginResult) => {
        if (response.loggedIn) {
            window.location.replace(response.redirectUri);
        } else {
            toggleClass(this.config.ErrorMessageLabel, "d-none", response.loginMessage == "");
            this.config.ErrorMessageLabel.innerHTML = response.loginMessage;

            let totpRequired = response.requiredCredentials.filter(c => c.type == "totp" || c.type == "hwtotp").length > 0;

            //if (totpRequired) response.requiredCredentials.pop(); // TODO: check if the order of the credentials is always guaranteed

            toggleClass(this.config.TotpInputGroup, "d-none", !totpRequired);
            this.config.TotpInput.focus();

            let fido2Required = response.requiredCredentials.filter(c => c.type == "fido2").length > 0;
            toggleClass(this.config.Fido2LoginButton, "d-none", !fido2Required);
            this.config.Fido2LoginButton.disabled = !fido2Required;

            toggleClass(this.config.LoginButton, "d-none", !totpRequired && fido2Required);
        }
    }

    private OnLoginFail = (error: any) => {
        console.error(error);
        toggleClass(this.config.ErrorMessageLabel, "d-none", false);
        this.config.ErrorMessageLabel.innerText = error?.toString() ?? "Error";
    }
}