const baseURL = "https://login.rescuetrack.com"

enum ContentType {
    JSON = 'application/json'
}

type HTTPError = {
    text: string
    code: number
}

export async function postJSON<T = any>(url: string, data: any, isExternalEnv: boolean): Promise<T> {
    const response = await fetch(isExternalEnv ? baseURL + url : url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: makeJSONHTTPHeaders()
    })
    if (!response.ok) throw new Error(response.statusText)

    const isJSON = response.headers.get('content-type')?.includes(ContentType.JSON)
    if (isJSON === true) {
        return response.json()
    }
}

export async function fetchJSON(url: string, isExternalEnv: boolean): Promise<any> {
    const response = await fetch(isExternalEnv ? baseURL + url : url, {
        headers: makeJSONHTTPHeaders()
    })
    if (!response.ok || response.status === 204) throw {text: response.statusText, code: response.status} as HTTPError

    return response.json()
}

function makeJSONHTTPHeaders(): Headers {
    const headers = new Headers()
    headers.append('Content-Type', ContentType.JSON)
    headers.append('Accept', ContentType.JSON )
    return headers
}

export function toggleClass(element: HTMLElement, className: string, force: boolean) {
    if (element == null) return
    if (force) {
        element.classList.add(className)
    } else {
        element.classList.remove(className)
    }
}
