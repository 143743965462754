﻿import {PasswordPolicyValidationRequest, CheckCurrentPasswordResult} from './ChangePasswordController'
import { postJSON, toggleClass } from './Helper';

export interface ChangeFido2ControllerConfig {
    Form: HTMLFormElement;
    PasswordInput: HTMLInputElement;
    IdentifierInput: HTMLInputElement;
}

export class Fido2Controller {
    private config: ChangeFido2ControllerConfig
    private isExternalEnv: boolean;

    constructor(config: ChangeFido2ControllerConfig, isExternalEnv: boolean) {
        this.config = config;
        this.isExternalEnv = isExternalEnv;
        this.AttachEventHandlers();
    }

    private AttachEventHandlers = () => {
        this.config.Form?.addEventListener('submit', this.OnChangeFido2FormSubmit);
        this.config.PasswordInput?.addEventListener("input", this.OnPasswordInputChanged);
    }

    private OnPasswordInputChanged = () => {
        let password = this.config.PasswordInput?.value;
        this.ValidatePassword(password);
    }

    private ValidatePassword = (password: string) => {
        let request: PasswordPolicyValidationRequest = {
            password: password
        };

        postJSON("/api/authentication/v1/checkCurrentPassword", request, this.isExternalEnv)
            .then(result => this.OnCurrentPasswordValidated(result))
            .catch(reason => console.log(reason))
    }

    private OnCurrentPasswordValidated = (response: CheckCurrentPasswordResult) => {
        toggleClass(this.config.PasswordInput, "is-valid", response.matches);
        toggleClass(this.config.PasswordInput, "is-invalid", !response.matches);
    }

    private OnChangeFido2FormSubmit = (e: any) => {
        e.preventDefault();
        console.log("register fido2");
        var request = {
            //this.config.
        };

        postJSON("/api/authentication/v1/makeCredentialOptions", request, this.isExternalEnv)
            .then(result => this.OnMakeCredential(result))
            .catch(reason => console.log(reason))
    }

    private OnMakeCredential(makeCredentialOptions) {
        console.log(makeCredentialOptions);

        makeCredentialOptions.challenge = this.coerceToArrayBuffer(makeCredentialOptions.challenge);
        // Turn ID into a UInt8Array Buffer for some reason
        makeCredentialOptions.user.id = this.coerceToArrayBuffer(makeCredentialOptions.user.id);

        makeCredentialOptions.excludeCredentials = makeCredentialOptions.excludeCredentials.map((c: any) => {
            c.id = this.coerceToArrayBuffer(c.id);
            return c;
        });

        if (makeCredentialOptions.authenticatorSelection.authenticatorAttachment === null) makeCredentialOptions.authenticatorSelection.authenticatorAttachment = undefined;

        console.log("Credential Options Formatted", makeCredentialOptions);

        console.log("Creating PublicKeyCredential...");

        var newCredential = navigator.credentials.create({
            publicKey: makeCredentialOptions
        }).then( (x) => {
            console.log(x);

            let makeCredentialRequest = {
                //"username": request.username,
                "identifier": this.config.IdentifierInput?.value,
                "id": x.id,
                /*"rawId": ab2str(x.rawId),
                "attestationObject": ab2str(x.attestationObject),
                "clientDataJson": ab2str(x.clientDataJSON)*/
                "rawId": this.bytesToBase64(new Uint8Array((<any>x).rawId)),
                "attestationObject": this.bytesToBase64(new Uint8Array((<any>x).response.attestationObject)),
                "clientDataJson": this.bytesToBase64(new Uint8Array((<any>x).response.clientDataJSON)),
                "password": this.config.PasswordInput?.value
            };
            console.warn(makeCredentialRequest);

            postJSON("/api/authentication/v1/makeCredential", makeCredentialRequest, this.isExternalEnv)
                .then(result => {
                    document.getElementById("change-fido2-success-message").classList.remove("d-none");
                    document.getElementById("change-fido2-error-message").classList.add("d-none");
                    document.querySelectorAll("button,a").forEach((element: HTMLElement) => element.setAttribute('disabled', ''));
                    setTimeout(() => {
                        location.href = "managesecondfactor.html";
                    }, 3000)
                })
                .catch(reason => {
                    document.getElementById("change-fido2-error-message").classList.remove("d-none");
                document.getElementById("change-fido2-success-message").classList.add("d-none");
                })
        });
    }

    private coerceToArrayBuffer(thing: any, name = "no name") {
        if (typeof thing === "string") {
            // base64url to base64
            thing = thing.replace(/-/g, "+").replace(/_/g, "/");

            // base64 to Uint8Array
            var str = window.atob(thing);
            var bytes = new Uint8Array(str.length);
            for (var i = 0; i < str.length; i++) {
                bytes[i] = str.charCodeAt(i);
            }
            thing = bytes;
        }

        // Array to Uint8Array
        if (Array.isArray(thing)) {
            thing = new Uint8Array(thing);
        }

        // Uint8Array to ArrayBuffer
        if (thing instanceof Uint8Array) {
            thing = thing.buffer;
        }

        // error if none of the above worked
        if (!(thing instanceof ArrayBuffer)) {
            throw new TypeError("could not coerce '" + name + "' to ArrayBuffer");
        }

        return thing;
    };

    private base64abc = (() => {
        let abc = [],
            A = "A".charCodeAt(0),
            a = "a".charCodeAt(0),
            n = "0".charCodeAt(0);
        for (let i = 0; i < 26; ++i) {
            abc.push(String.fromCharCode(A + i));
        }
        for (let i = 0; i < 26; ++i) {
            abc.push(String.fromCharCode(a + i));
        }
        for (let i = 0; i < 10; ++i) {
            abc.push(String.fromCharCode(n + i));
        }
        abc.push("+");
        abc.push("/");
        return abc;
    })();

    private bytesToBase64(bytes: any) {
        let result = '', i, l = bytes.length;
        for (i = 2; i < l; i += 3) {
            result += this.base64abc[bytes[i - 2] >> 2];
            result += this.base64abc[((bytes[i - 2] & 0x03) << 4) | (bytes[i - 1] >> 4)];
            result += this.base64abc[((bytes[i - 1] & 0x0F) << 2) | (bytes[i] >> 6)];
            result += this.base64abc[bytes[i] & 0x3F];
        }
        if (i === l + 1) { // 1 octet missing
            result += this.base64abc[bytes[i - 2] >> 2];
            result += this.base64abc[(bytes[i - 2] & 0x03) << 4];
            result += "==";
        }
        if (i === l) { // 2 octets missing
            result += this.base64abc[bytes[i - 2] >> 2];
            result += this.base64abc[((bytes[i - 2] & 0x03) << 4) | (bytes[i - 1] >> 4)];
            result += this.base64abc[(bytes[i - 1] & 0x0F) << 2];
            result += "=";
        }
        return result;
    }
}