﻿import { postJSON, toggleClass } from "./Helper";

export interface ChangePasswordControllerConfig {
    Form: HTMLFormElement;
    ResetPasswordTokenInput: HTMLInputElement;
    CurrentPasswordInput: HTMLInputElement;
    NewPasswordInput: HTMLInputElement;
    RepeatNewPasswordInput: HTMLInputElement;
}

interface ResetPasswordTokenExistsRequest {
    token: string;
}

interface ResetPasswordTokenExistsResult {
    isValid: boolean;
}

interface CheckCurrentPasswordRequest {
    password: string;
}

export interface CheckCurrentPasswordResult {
    matches: boolean;
}

export interface PasswordPolicyValidationRequest {
    password: string;
}

interface PasswordPolicyValidationResult {
    isValid: boolean;
    violatedPolicyMessage: string;
}

interface ChangePasswordRequest {
    currentPassword: string;
    newPassword: string;
}

export interface ChangeCredentialsResult {
    success: boolean;
    errorMessage: string;
}

interface ResetPasswordStep2Request {
    token: string;
    newPassword: string;
}

export class ChangePasswordController {
    private config: ChangePasswordControllerConfig;
    private isExternalEnv: boolean;

    constructor(config: ChangePasswordControllerConfig, isExternalEnv: boolean) {
        this.isExternalEnv = isExternalEnv
        this.config = config;
        this.AttachEventHandlers();
        if (this.getUrlParameter("token") !== null) {
            this.config.ResetPasswordTokenInput.value = this.getUrlParameter("token").toString();
            this.OnResetPasswordTokenInputChanged();
        }
    }

    private AttachEventHandlers = () => {
        this.config.Form?.addEventListener('submit', this.OnChangePasswordFormSubmit);
        this.config.ResetPasswordTokenInput?.addEventListener("input", this.OnResetPasswordTokenInputChanged);
        this.config.CurrentPasswordInput?.addEventListener("input", this.OnChangePasswordCurrentPasswordInputChanged);
        this.config.NewPasswordInput?.addEventListener("input", this.OnChangePasswordNewPasswordInputChanged);
        this.config.RepeatNewPasswordInput?.addEventListener("input", this.OnChangePasswordRepeatNewPasswordInputChanged);
    }
    
    private OnChangePasswordCurrentPasswordInputChanged = () => {
        let password = this.config.CurrentPasswordInput?.value;
        this.ValidateCurrentPassword(password);
    }

    private OnResetPasswordTokenInputChanged = () => {
        let token = this.config.ResetPasswordTokenInput?.value
        this.ValidateCurrentToken(token);
    }

    private ValidateCurrentToken = (token: string) => {
        let request: ResetPasswordTokenExistsRequest = {
            token: token
        };

        postJSON("/api/authentication/v1/checkIfResetPasswordTokenExists", request, this.isExternalEnv)
            .then(result => this.OnResetPasswordTokenValidated(result))
            .catch(reason => console.log(reason));
    }

    private OnResetPasswordTokenValidated = (response: ResetPasswordTokenExistsResult) => {
        toggleClass(this.config.ResetPasswordTokenInput, "is-valid", response.isValid);
        toggleClass(this.config.ResetPasswordTokenInput, "is-invalid", !response.isValid);
        if (response.isValid) this.config.NewPasswordInput.focus();
    }

    private ValidateCurrentPassword = (password: string) => {
        let request: PasswordPolicyValidationRequest = {
            password: password
        };

        postJSON("/api/authentication/v1/checkCurrentPassword", request, this.isExternalEnv)
            .then(result => this.OnCurrentPasswordValidated(result))
            .catch(reason => console.log(reason));
    }

    private OnCurrentPasswordValidated = (response: CheckCurrentPasswordResult) => {
        toggleClass(this.config.CurrentPasswordInput, "is-valid", response.matches);
        toggleClass(this.config.CurrentPasswordInput, "is-invalid", !response.matches);
    }

    private OnChangePasswordRepeatNewPasswordInputChanged = ()  =>{
        this.ValidateIfNewPasswordsMatch();
    }

    private ValidateIfNewPasswordsMatch = () => {
        let newPassword = this.config.NewPasswordInput.value;
        let repeatNewPassword = this.config.RepeatNewPasswordInput.value;

        let passwordsMatch = newPassword == repeatNewPassword;
        toggleClass(this.config.RepeatNewPasswordInput, "is-valid", passwordsMatch);
        toggleClass(this.config.RepeatNewPasswordInput, "is-invalid", !passwordsMatch);
    }

    private OnChangePasswordNewPasswordInputChanged = () => {
        let password = this.config.NewPasswordInput.value;
        this.ValidateNewPassword(password);
    }

    private ValidateNewPassword = (password: string) => {
        let request: PasswordPolicyValidationRequest = {
            password: password
        };

        postJSON("/api/authentication/v1/checkPasswordPolicy", request, this.isExternalEnv)
            .then(result => this.OnNewPasswordValidated(result))
            .catch(reason => console.log(reason));
    }

    private OnNewPasswordValidated = (result: PasswordPolicyValidationResult) => {  
        toggleClass(this.config.NewPasswordInput, "is-valid", result.isValid);
        toggleClass(this.config.NewPasswordInput, "is-invalid", !result.isValid);
        let violatedPolicyMessageAvailable = result.violatedPolicyMessage != null && result.violatedPolicyMessage != "";
        document.getElementById("password-policy").innerText = violatedPolicyMessageAvailable ? result.violatedPolicyMessage : "Password doesn't match policy.";
    }

    private getUrlParameter(parameterName: string) {
        var urlVariables = window.location.search.substring(1).split("&");

        for (var i = 0; i < urlVariables.length; i++) {
            var sParameterName = urlVariables[i].split("=");

            if (sParameterName[0] === parameterName) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
        return null;
    }

    private OnChangePasswordFormSubmit = (e: any) => {
        e.preventDefault();

        var changePasswordToken = this.config.ResetPasswordTokenInput?.value;
        var newPassword = this.config.NewPasswordInput?.value;
        var repeatNewPassword = this.config.RepeatNewPasswordInput?.value;

        if (newPassword != repeatNewPassword) {
            document.getElementById("changePassword-error-message").classList.remove("d-none");
            document.getElementById("changePassword-error-message").innerText = "Passwords mismatch";
            return;
        }

        if (changePasswordToken == null) {
            const request: ChangePasswordRequest = {
                currentPassword: this.config.CurrentPasswordInput?.value,
                newPassword: newPassword
            };


            postJSON("/api/authentication/v1/changePassword", request, this.isExternalEnv)
                .then(result => this.OnChangePasswordSuccess(result))
                .catch(reason => this.OnChangePasswordFail());
        } else {
            var resetPasswordStep2Request: ResetPasswordStep2Request = {
                token: changePasswordToken.toString(),
                newPassword: newPassword
            };

            postJSON("/api/authentication/v1/resetPasswordStep2", resetPasswordStep2Request, this.isExternalEnv)
                .then(result => this.OnChangePasswordSuccess(result))
                .catch(reason => this.OnChangePasswordFail());
        }
    }

    private OnChangePasswordSuccess = (response: ChangeCredentialsResult) => {
        toggleClass(document.getElementById("changePassword-success-message"), "d-none", !response.success);
        let errorMessageAvailable = response.errorMessage != null && response.errorMessage != "";
        toggleClass(document.getElementById("changePassword-error-message"), "d-none", response.success);
        document.getElementById("changePassword-error-message").innerText = errorMessageAvailable ? response.errorMessage : "";
        setTimeout(function () { document.location.href = "index.html" }, 2000);
    }

    private OnChangePasswordFail = () => {
        console.error("password change failed");
    }
}