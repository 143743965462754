﻿import {LoginControllerConfig, LoginController} from "./LoginController"
import {HomeControllerConfig, HomeController} from "./HomeController"
import {ChangePasswordControllerConfig, ChangePasswordController } from "./ChangePasswordController"
import {ChangeTotpControllerConfig, ChangeTotpController} from "./ChangeTotpController"
import {ChangeHwTotpControllerConfig, ChangeHwTotpController} from "./ChangeHwTotpController"
import {ChangeFido2ControllerConfig, Fido2Controller} from "./Fido2Controller"
import {SecondFactorControllerConfig, SecondFactorController} from "./SecondFactorController"
import {DeleteSecondFactorControllerConfig, DeleteSecondFactorController} from "./DeleteSecondFactorController"
import { getPictureOfTheDay } from "./pictureOfTheDay"

import 'bootstrap/dist/css/bootstrap.min.css'

import 'core-js/modules/es.promise.js'
import 'core-js/modules/web.url-search-params.js'
import 'whatwg-fetch'

document.addEventListener('DOMContentLoaded', OnDocumentReady)

async function OnDocumentReady() {
    let loginControllerConfig: LoginControllerConfig = {
        ErrorMessageLabel: document.getElementById("login-error-message"),
        Form: document.getElementById("login-form") as HTMLFormElement,
        ResetPasswordForm: document.getElementById("reset-password-form") as HTMLFormElement,
        UsernameInput: document.getElementById("login-username") as HTMLInputElement,
        PasswordInput: document.getElementById("login-password") as HTMLInputElement,
        TotpInput: document.getElementById("login-totp") as HTMLInputElement,
        TotpInputGroup: document.getElementById("login-totp-group") as HTMLInputElement,
        Fido2LoginButton: document.getElementById("login-fido2") as HTMLInputElement,
        LoginButton: document.getElementById("login-button") as HTMLInputElement
    }
    
    let homeControllerConfig: HomeControllerConfig = {
        DisplayNameLabel: document.getElementById("current-displayname"),
        PortalTitle: document.querySelector("#portal-title h1"),
        PortalSubTitle: document.querySelector("#portal-title h2"),
        PortalLegalInfo: document.querySelector("#portal-legalInfo")
    }
    
    let changePasswordControllerConfig: ChangePasswordControllerConfig = {
        Form: document.getElementById("changePassword-form") as HTMLFormElement,
        ResetPasswordTokenInput: document.getElementById("changePassword-token") as HTMLInputElement,
        CurrentPasswordInput: document.getElementById("changePassword-current-password") as HTMLInputElement,
        NewPasswordInput: document.getElementById("changePassword-new-password") as HTMLInputElement,
        RepeatNewPasswordInput: document.getElementById("changePassword-repeat-new-password") as HTMLInputElement
    }
    
    let changeTotpControllerConfig: ChangeTotpControllerConfig = {
        Form: document.getElementById("change-totp-form") as HTMLFormElement,
        PasswordInput: document.getElementById("change-totp-password") as HTMLInputElement,
        CodeInput: document.getElementById("change-totp-code") as HTMLInputElement,
        IdentifierInput: document.getElementById("change-totp-identifier") as HTMLInputElement,
        QrCanvasElement: <HTMLCanvasElement>document.getElementById("qr"),
        SuccessMessageLabel: document.getElementById("change-totp-success-message"),
        ErrorMessageLabel: document.getElementById("change-totp-error-message")
    }
    
    let changeHwTotpControllerConfig: ChangeHwTotpControllerConfig = {
        CodeInput: document.getElementById("change-hwtotp-code") as HTMLInputElement,
        IdentifierInput: document.getElementById("change-hwtotp-identifier") as HTMLInputElement,
        Form: document.getElementById("change-hwtotp-form") as HTMLFormElement,
        PasswordInput: document.getElementById("change-hwtotp-password") as HTMLInputElement,
        SerialInput: document.getElementById("change-hwtotp-serial") as HTMLInputElement,
        SuccessMessageLabel: document.getElementById("change-totp-success-message"),
        ErrorMessageLabel: document.getElementById("change-totp-error-message")
    }
    
    let changeFido2ControllerConfig: ChangeFido2ControllerConfig = {
        Form: document.getElementById("change-fido2-form") as HTMLFormElement,
        PasswordInput: document.getElementById("change-fido2-password") as HTMLInputElement,
        IdentifierInput: document.getElementById("change-fido2-identifier") as HTMLInputElement
    }
    
    let secondFactorControllerConfig: SecondFactorControllerConfig = {
        TotpList: document.getElementById("totp-list"),
        HwTotpList: document.getElementById("hwtotp-list"),
        Fido2List: document.getElementById("fido2-list")
    }
    
    let deleteSecondFactorControllerConfig: DeleteSecondFactorControllerConfig = {
        Form: document.getElementById("delete-secondfactor-form") as HTMLFormElement,
        IdentifierInput: document.getElementById("delete-secondfactor-identifier") as HTMLInputElement,
        PasswordInput: document.getElementById("delete-secondfactor-password") as HTMLInputElement
    }

    let isExternalEnv = false;
    const homeController = new HomeController(homeControllerConfig);
    try {
        await homeController.UpdatePortalSettings();
    } catch (error) {
        if(error.code === 404) isExternalEnv = true;
    }
    
    homeController.UpdateDisplayName(isExternalEnv);

    new LoginController(loginControllerConfig, isExternalEnv);
    new ChangePasswordController(changePasswordControllerConfig, isExternalEnv);
    new ChangeTotpController(changeTotpControllerConfig, isExternalEnv);
    new ChangeHwTotpController(changeHwTotpControllerConfig, isExternalEnv);
    new Fido2Controller(changeFido2ControllerConfig, isExternalEnv);
    new SecondFactorController(secondFactorControllerConfig, isExternalEnv);
    new DeleteSecondFactorController(deleteSecondFactorControllerConfig, isExternalEnv);

    FocusFirstInput();
    HandleUrlParameters(loginControllerConfig);
    getPictureOfTheDay(isExternalEnv);
}

function FocusFirstInput() {
    (document.querySelector("form .form-group:first-child input") as HTMLInputElement).focus();
}

function HandleUrlParameters(loginControllerConfig: LoginControllerConfig) {
    let queryParameters = new URLSearchParams(window.location.search);
    if (queryParameters.has('login_hint')) {
        loginControllerConfig.UsernameInput.value = queryParameters.get("login_hint");
        loginControllerConfig.PasswordInput.focus();
    }
}