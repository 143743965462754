﻿import {PasswordPolicyValidationRequest, CheckCurrentPasswordResult, ChangeCredentialsResult} from "./ChangePasswordController"
import { postJSON, toggleClass } from './Helper'

export interface ChangeHwTotpControllerConfig {
    Form: HTMLFormElement,
    PasswordInput: HTMLInputElement;
    CodeInput: HTMLInputElement;
    IdentifierInput: HTMLInputElement;
    SerialInput: HTMLInputElement;
    SuccessMessageLabel: HTMLElement;
    ErrorMessageLabel: HTMLElement;
}

interface CheckHwTotpCodeRequest {
    serial: string;
    code: string
}

interface CheckHwTotpCodeResult {
    matches: boolean;
}

interface CheckHwTotpSerialRequest {
    serial: string;
}

interface CheckHwTotpSerialResult {
    exists: boolean;
}

interface ChangeHwTotpRequest {
    password: string;
    serial: string;
    code: string;
    identifier: string;
}

interface ChangeHwTotpResult {
    success: boolean;
    errorMessage: string;
}

export class ChangeHwTotpController {
    private config: ChangeHwTotpControllerConfig
    private isExternalEnv: boolean

    constructor(config: ChangeHwTotpControllerConfig, isExternalEnv: boolean) {
        this.config = config;
        this.isExternalEnv = isExternalEnv
        this.AttachEventHandlers();
    }

    private AttachEventHandlers = () => {
        this.config.Form?.addEventListener('submit', this.OnChangeHwTotpFormSubmit)
        this.config.PasswordInput?.addEventListener("input", this.OnPasswordInputChanged);
        this.config.SerialInput?.addEventListener("input", this.OnSerialInputChanged);
        this.config.CodeInput?.addEventListener("input", this.OnTotpCodeInputChanged)
    }

    private OnPasswordInputChanged = () => {
        let password = this.config.PasswordInput?.value
        this.ValidatePassword(password);
    }

    private ValidatePassword = (password: string) => {
        let request: PasswordPolicyValidationRequest = {
            password: password
        };

        postJSON("/api/authentication/v1/checkCurrentPassword", request, this.isExternalEnv)
            .then(result =>  this.OnCurrentPasswordValidated(result))
            .catch(reason => console.log(reason));
    }

    private OnCurrentPasswordValidated = (response: CheckCurrentPasswordResult) => {
        toggleClass(this.config.PasswordInput, "is-valid", response.matches);
        toggleClass(this.config.PasswordInput, "is-invalid", !response.matches);
        if (response.matches) this.config.IdentifierInput.focus();
    }

    private OnSerialInputChanged = () => {
        let serial = this.config.SerialInput?.value
        this.CheckIfSerialExists(serial);
    }

    private CheckIfSerialExists = (serial: string) => {
        let request: CheckHwTotpSerialRequest = {
            "serial": serial
        };

        postJSON("/api/authentication/v1/checkHwToptSerial", request, this.isExternalEnv)
            .then(result => this.OnSerialChecked(result))
            .catch(reason => console.log(reason));
    }

    private OnSerialChecked = (response: CheckHwTotpSerialResult) => {
        toggleClass(this.config.SerialInput, "is-valid", response.exists);
        toggleClass(this.config.SerialInput, "is-invalid", !response.exists);
        if (response.exists) this.config.CodeInput?.focus();
    }

    private OnTotpCodeInputChanged = () => {
        let serial = this.config.SerialInput?.value;
        let code = this.config.CodeInput?.value;
        this.ValidateCode(serial, code);
    }

    private ValidateCode = (serial: string, code: string) => {
        let request: CheckHwTotpCodeRequest = {
            serial: serial,
            code: code
        };

        
        postJSON("/api/authentication/v1/checkHwTotpCode", request, this.isExternalEnv)
            .then(result => this.OnTotpCodeValidated(result))
            .catch(reason => console.log(reason));
    }

    private OnTotpCodeValidated = (response: CheckHwTotpCodeResult) => {
        toggleClass(this.config.CodeInput, "is-valid", response.matches);
        toggleClass(this.config.CodeInput, "is-invalid", !response.matches);
    }

    private OnChangeHwTotpFormSubmit = (e: any) => {
        e.preventDefault();

        var request: ChangeHwTotpRequest = {
            password: this.config.PasswordInput?.value,
            serial: this.config.SerialInput?.value,
            code: this.config.CodeInput?.value,
            identifier: this.config.IdentifierInput?.value
        };

        postJSON("/api/authentication/v1/changeHwTotp", request, this.isExternalEnv)
            .then(result => this.OnChangeHwTotpSuccess(result))
            .catch(reason => this.OnChangeHwTotpFail(reason));
    }

    private OnChangeHwTotpSuccess = (response: ChangeCredentialsResult) => {
        toggleClass(this.config.SuccessMessageLabel, "d-none", !response.success);
        let errorMessageAvailable = response.errorMessage != null && response.errorMessage != "";
        toggleClass(this.config.ErrorMessageLabel, "d-none", response.success);
        this.config.ErrorMessageLabel.innerText = (errorMessageAvailable ? response.errorMessage : "");
        if (response.success) {
            document.querySelectorAll("button,a").forEach(element => element.setAttribute('disabled', ''));
            setTimeout(function () { location.href = "managesecondfactor.html"; }, 3000);
        }
    }

    private OnChangeHwTotpFail = (reason: any) => {
        console.error(reason);
    }
}