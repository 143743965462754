﻿import { fetchJSON } from "./Helper";

export interface HomeControllerConfig {
    DisplayNameLabel: HTMLElement
    PortalTitle: HTMLElement
    PortalSubTitle: HTMLElement
    PortalLegalInfo: HTMLElement
}

interface FrontendUserInformation {
    displayName: string;
}

interface PortalSettings {
    title: string;
    subTitle: string;
    legalInfo: string
}

export class HomeController {
    private config: HomeControllerConfig

    constructor(config: HomeControllerConfig) {
        this.config = config;
    }

    public UpdateDisplayName = (isExternalEnv: boolean) => {
        fetchJSON("/api/authentication/v1/getUserInformation", isExternalEnv)
            .then(result => {
                this.OnGetUserInformationSuccess(result)
            })
            .catch(reason => {
                this.OnGetUserInformationFail()
            })
    }

    public UpdatePortalSettings = (): Promise<void> => {
        return fetchJSON("/api/authentication/v1/getPortalSettings", false)
            .then(result => {
                this.OnGetPortalSettingsSuccess(result)
            })
    }

    private OnGetPortalSettingsSuccess = (response: PortalSettings) => {
        this.config.PortalTitle.innerText = response.title;
        this.config.PortalSubTitle.innerText = response.subTitle;
        this.config.PortalLegalInfo.innerHTML = response.legalInfo;
    }

    private OnGetUserInformationSuccess = (response: FrontendUserInformation) => {
        this.config.DisplayNameLabel.innerText = response.displayName;
    }

    private OnGetUserInformationFail = () => {
        if (window.location.href.indexOf("index.html") != -1) window.location.href = "login.html";
    }
}
