﻿import {PasswordPolicyValidationRequest, CheckCurrentPasswordResult} from './ChangePasswordController'
import { postJSON, toggleClass } from './Helper';

export interface DeleteSecondFactorControllerConfig {
    Form: HTMLFormElement,
    IdentifierInput: HTMLInputElement;
    PasswordInput: HTMLInputElement;
}

interface DeleteSecondFactorRequest {
    identifier: string;
    password: string;
}

interface DeleteSecondFactorResult {
    success: boolean;
}

export class DeleteSecondFactorController {
    private config: DeleteSecondFactorControllerConfig;
    private isExternalEnv: boolean;

    constructor(config: DeleteSecondFactorControllerConfig, isExternalEnv: boolean) {
        this.config = config;
        this.isExternalEnv = isExternalEnv
        this.AttachEventHandlers();
        let parameters = this.GetUrlParameters();
        if (parameters["identifier"] != null) this.config.IdentifierInput.value = parameters["identifier"];
        setTimeout(() => { 
            this.config.PasswordInput?.focus();
         }, 50);
    }

    private GetUrlParameters(): { [key: string]: string } {
        const params: {[key: string]: string} = {};
        const queryString = window.location.search.substring(1);
        const queries = queryString.split("&");

        queries.forEach((indexQuery: string) => {
            const indexPair = indexQuery.split("=");

            const queryKey = decodeURIComponent(indexPair[0]);
            const queryValue = decodeURIComponent(indexPair.length > 1 ? indexPair[1] : "");

            params[queryKey] = queryValue;
        });

        return params;
    }

    private AttachEventHandlers = () => {
        this.config.Form?.addEventListener('submit', this.OnFormSubmit);
        this.config.PasswordInput?.addEventListener("input", this.OnPasswordInputChanged);
    }

    private OnFormSubmit = (e: any) => {
        e.preventDefault();

        let request: DeleteSecondFactorRequest = {
            identifier: this.config.IdentifierInput?.value,
            password: this.config.PasswordInput?.value
        };

        postJSON("/api/authentication/v1/deleteSecondFactor", request, this.isExternalEnv)
        .then(result => {
            if (result.success) {
                document.getElementById("delete-secondfactor-success-message").classList.remove("d-none");
                document.getElementById("delete-secondfactor-error-message").classList.add("d-none");
                document.querySelectorAll("button,a").forEach(element => element.setAttribute('disabled', ''));
                setTimeout(function () { document.location.href = "managesecondfactor.html" }, 3000);
            } else {
                document.getElementById("delete-secondfactor-error-message").classList.remove("d-none");
                document.getElementById("delete-secondfactor-success-message").classList.add("d-none");
            }
        })
        .catch(reason => this.OnDeleteSecondFactorFail())
    }

    private OnDeleteSecondFactorFail = () => {
        console.error("delete of secondfactor failed");
    }

    private OnPasswordInputChanged = () => {
        let password = this.config.PasswordInput?.value;
        this.ValidateCurrentPassword(password);
    }

    private ValidateCurrentPassword = (password: string) => {
        let request: PasswordPolicyValidationRequest = {
            password: password
        };

        postJSON("/api/authentication/v1/checkCurrentPassword", request, this.isExternalEnv)
        .then(result => {
            this.OnCurrentPasswordValidated(result)
        })
        .catch(reason => console.log(reason))
    }

    private OnCurrentPasswordValidated = (response: CheckCurrentPasswordResult) => {
        toggleClass(this.config.PasswordInput, "is-valid", response.matches);
        toggleClass(this.config.PasswordInput, "is-invalid", !response.matches);
    }
}