﻿import {PasswordPolicyValidationRequest, CheckCurrentPasswordResult, ChangeCredentialsResult} from './ChangePasswordController'
import QRious from 'qrious'
import { postJSON, toggleClass, fetchJSON } from './Helper';

export interface ChangeTotpControllerConfig {
    Form: HTMLFormElement,
    PasswordInput: HTMLInputElement;
    CodeInput: HTMLInputElement;
    IdentifierInput: HTMLInputElement;
    QrCanvasElement: HTMLCanvasElement;
    SuccessMessageLabel: HTMLElement;
    ErrorMessageLabel: HTMLElement;
}

interface CheckTotpSecretRequest {
    codeForNewSecret: string;
}

interface ChangeTotpRequest {
    password: string;
    codeForNewSecret: string;
    identifier: string;
}

interface CheckTotpSecretResult {
    codeIsCorrect: boolean;
}

interface GetUriForAuthenticatorResult {
    uri: string;
}

export class ChangeTotpController {
    private config: ChangeTotpControllerConfig
    private isExternalEnv: boolean;

    constructor(config: ChangeTotpControllerConfig, isExternalEnv: boolean) {
        this.config = config;
        this.isExternalEnv = isExternalEnv;
        this.AttachEventHandlers();
        this.UpdateQrCodeForAuthenticator();
    }

    private AttachEventHandlers = () => {
        this.config.Form?.addEventListener('submit', this.OnChangeTotpFormSubmit);
        this.config.PasswordInput?.addEventListener("input", this.OnPasswordInputChanged);
        this.config.CodeInput?.addEventListener("input", this.OnTotpCodeInputChanged)
    }

    private OnPasswordInputChanged = () => {
        let password = this.config.PasswordInput?.value;
        this.ValidatePassword(password);
    }

    private ValidatePassword = (password: string) => {
        let request: PasswordPolicyValidationRequest = {
            password: password
        };

        postJSON("/api/authentication/v1/checkCurrentPassword", request, this.isExternalEnv)
            .then(result => this.OnCurrentPasswordValidated(result))
            .catch(reason => console.log(reason))
    }

    private OnCurrentPasswordValidated = (response: CheckCurrentPasswordResult) => {
        toggleClass(this.config.PasswordInput, "is-valid", response.matches);
        toggleClass(this.config.PasswordInput, "is-invalid", !response.matches);
        if (response.matches) this.config.IdentifierInput?.focus();
    }

    private OnTotpCodeInputChanged = () => {
        let code = this.config.CodeInput?.value;
        this.ValidateCode(code);
    }

    private ValidateCode = (code: string) => {
        let request: CheckTotpSecretRequest = {
            codeForNewSecret: code
        };

        postJSON("/api/authentication/v1/checkTotpSecret", request, this.isExternalEnv)
            .then(result => this.OnTotpCodeValidated(result))
            .catch(reason => console.log(reason))
    }

    private OnTotpCodeValidated = (response: CheckTotpSecretResult) => {
        toggleClass(document.getElementById("change-totp-code"), "is-valid", response.codeIsCorrect);
        toggleClass(document.getElementById("change-totp-code"), "is-invalid", !response.codeIsCorrect);
    }

    private OnChangeTotpFormSubmit = (e: any) => {
        e.preventDefault();

        var request: ChangeTotpRequest = {
            password: this.config.PasswordInput.value,
            codeForNewSecret: this.config.CodeInput.value,
            identifier: this.config.IdentifierInput.value
        };

        postJSON("/api/authentication/v1/changeTotpSecret", request, this.isExternalEnv)
            .then(result => this.OnChangeTotpSuccess(result))
            .catch(reason => this.OnChangeTotpFail())
    }

    private UpdateQrCodeForAuthenticator = () => {
        fetchJSON("/api/authentication/v1/getUriForAuthenticator", this.isExternalEnv)
            .then(result => {
                this.OnQrCodeValueForAuthenticatorReceived(result)
            })
            .catch(reason => console.log(reason))
    }

    private OnQrCodeValueForAuthenticatorReceived = (response: GetUriForAuthenticatorResult) => {
        new QRious({
            element: this.config.QrCanvasElement,
            value: response.uri,
            size: 418,
            padding: 60
        });
    }

    private OnChangeTotpSuccess = (response: ChangeCredentialsResult) => {
        toggleClass(this.config.SuccessMessageLabel, "d-none", !response.success);
        if (response.success) {
            document.querySelectorAll("button,a").forEach(element => element.setAttribute('disabled', ''));
            setTimeout(function () { location.href = "managesecondfactor.html"; }, 3000);
        }
        let errorMessageAvailable = response.errorMessage != null && response.errorMessage != "";
        toggleClass(this.config.ErrorMessageLabel, "d-none", response.success);
        this.config.ErrorMessageLabel.innerText = (errorMessageAvailable ? response.errorMessage : "");
    }

    private OnChangeTotpFail = () => {
        console.error("password change failed");
    }
}