﻿import { fetchJSON } from "./Helper";

interface SecondFactorCredential {
    identifier: string;
    type: string;
}

export interface SecondFactorControllerConfig {
    TotpList: HTMLElement,
    HwTotpList: HTMLElement,
    Fido2List: HTMLElement;
}

export class SecondFactorController {
    private config: SecondFactorControllerConfig;
    private isExternalEnv: boolean;

    constructor(config: SecondFactorControllerConfig, isExternalEnv: boolean) {
        this.isExternalEnv = isExternalEnv
        this.config = config;
        this.GetSecondFactors();
    }

    private GetSecondFactors(): void {
        fetchJSON("/api/authentication/v1/secondFactors", this.isExternalEnv)
            .catch(error => {
                if (error.code === 204) return Promise.resolve([])
                else throw error
            }).then(result => {
                this.OnGetSecondFactors(result)
            }).catch(reason => {
                console.error(reason);
            })
    }

    private OnGetSecondFactors(data: SecondFactorCredential[]): void {
        if (data.length === 0) return;
        this.config.TotpList.innerHTML = this.FormatCredentials(data.filter(x => x.type == "totp"));
        this.config.HwTotpList.innerHTML = this.FormatCredentials(data.filter(x => x.type == "hwtotp"));
        this.config.Fido2List.innerHTML = this.FormatCredentials(data.filter(x => x.type == "fido2"));
    }

    private FormatCredentials(credentials: SecondFactorCredential[]): string {
        if (credentials.length == 0) return `<div class="alert alert-secondary mb-0" role="alert">Nicht hinterlegt</div>`;
        return credentials.map(this.FormatCredential).join("");
    }

    private FormatCredential(credential: SecondFactorCredential): string {
        return `<li class="list-group-item d-flex justify-content-between">
                        <span>${credential.identifier}</span>
                        <a class="btn btn-sm btn-primary" href="deletesecondfactor.html?identifier=${encodeURIComponent(credential.identifier)}">X</a>
                    </li>`;
    }
}